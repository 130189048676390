import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import { get } from 'helpers/utilities';

import styles from './styles.scss';

const NewsLink = ({ news, children, className }) =>
  get(news, 'linkType') === 'internalLink' ? (
    <Link to={{ pathname: `/${get(news, 'internalLink[0].uri')}` }} className={className}>
      {children}
    </Link>
  ) : (
    <a href={get(news, 'externalLink')} className={className}>
      {children}
    </a>
  );

const HomepagePromotion = ({ recentNews = [], className }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setTimeout(() => setFadeIn(true), 1000);
  });

  return (
    <div
      className={`${styles.homepagePromotion} ${
        fadeIn && styles.homepagePromotionFadeIn
      } ${className}`}>
      <div className={styles.homepagePromotion__wrapper}>
        {recentNews.map((news, index) => (
          <NewsLink
            news={news}
            className={styles.homepagePromotion__block}
            key={`news-item-${index}`}>
            {get(news, 'eyebrow') && (
              <div className={styles.homepagePromotion__blockLabel}>{get(news, 'eyebrow')}</div>
            )}

            <div
              className={
                get(news, 'eyebrow')
                  ? styles.homepagePromotion__content
                  : styles.homepagePromotion__contentWithoutEyebrow
              }>
              <div>
                <Image
                  src={get(news, 'image[0].url')}
                  alt=" "
                  lazyload={false}
                  disableSrcSet={true}
                  sizes={{ largeDesktop: 150 }}
                />
              </div>
              <p>{get(news, 'description')}</p>
            </div>
          </NewsLink>
        ))}
      </div>
    </div>
  );
};

export default HomepagePromotion;
