import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';
import { getAssociatedTaxonomy, getAssociatedTaxonomyConnection } from 'queries/twm_components';

// components
import TagHeader from 'components/TagHeader';
import Features from 'components/Features';
import CardGridComponent from 'components/CardGridComponent';
import Contact from 'components/Contact';
import NavigationThemeSetter from 'components/NavigationThemeSetter';
import withStickyFilterSetter from 'components/withStickyFilterSetter';
import withHOCs from 'components/withHOCs';
import StickyFilter, { StickyFilterProvider, useStickyFilter } from 'components/StickyFilter';
import Meta from 'components/Meta';

// queries
import tag from 'queries/tag';

import useTagContent from 'hooks/useTagContent';
import useContact from 'hooks/useContact';

const FeaturesWithStickyFilter = withStickyFilterSetter({
  componentName: 'features',
  color: 'white'
})(Features);
const CardGridComponentWithStickyFilter = withStickyFilterSetter({
  componentNa: 'cardgrid',
  color: 'black'
})(CardGridComponent);

const TagPage = ({ active, transporterHeadRef }) => {
  const location = useLocation();
  const prevURLRef = useRef('');
  const { setFilterOverlayOpen } = useStickyFilter();

  // TO DO: Remove additional page/ pattern once page prefix is dropped
  const url = location.pathname.replace(/^\/(page\/)?/, '');

  const tagContent = useTagContent(url);
  const gtmData = buildGTMData(tagContent);
  const contactData = useContact(tagContent);

  useEffect(() => {
    if (prevURLRef.current !== url) {
      setFilterOverlayOpen(false);
    }

    prevURLRef.current = url;
  }, [url]);

  if (!tagContent) return null;

  const { featuresModule = [] } = tagContent;

  const taxonomyTitle = get(getAssociatedTaxonomy(tagContent), 'tagTitle');
  const taxonomyCards = get(
    getAssociatedTaxonomyConnection(tagContent),
    'edges[0].relatedEntries.entries'
  );
  const metaDetails = {
    metaSection: get(tagContent, 'socialMetaSection[0]'),
    entryTitle: get(tagContent, 'title')
  };

  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      <NavigationThemeSetter navTheme="header">
        <TagHeader title={taxonomyTitle} pageBackground="" animateEntrance={true} />
      </NavigationThemeSetter>
      <StickyFilter title={get(tagContent, 'title')} transporterHeadRef={transporterHeadRef} />
      {featuresModule.length ? (
        <NavigationThemeSetter navTheme="white">
          <FeaturesWithStickyFilter features={featuresModule} componentQuery={tag.component(url)} />
        </NavigationThemeSetter>
      ) : null}
      {taxonomyCards.length ? (
        <NavigationThemeSetter navTheme="black">
          <CardGridComponentWithStickyFilter
            isTagPage={true}
            layout="simplified"
            cards={taxonomyCards}
            className="page-white-background"
          />
        </NavigationThemeSetter>
      ) : null}
      <NavigationThemeSetter navTheme="white">
        <Contact {...contactData} />
      </NavigationThemeSetter>
    </>
  );
};

export default withHOCs(StickyFilterProvider)(TagPage);
