import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import { stripParagraphTag } from 'helpers/text';
import classNames from 'classnames/bind';
import canUseDOM from 'helpers/canUseDOM';

import styles from './styles.scss';

const imageSizes = {
  mobile: 700,
  tablet: 450,
  smallDesktop: 600,
  largeDesktop: 1280
};

const cx = classNames.bind(styles);

const SingleBlowout = ({ data, stickyRef }) => {
  const {
    backgroundAsset,
    largeProjectAsset,
    clientName,
    caseStudyTitle,
    description,
    caseStudyLink,
    bulletedList,
    metric
  } = data;
  const metricNumberStyles = cx('singleBlowout__metricNumber', {
    'singleBlowout__metricNumber--4': get(metric, '[0].metricNumber', []).length === 4
  });

  // add aria-hidden on decorative images/background
  useEffect(() => {
    if (canUseDOM) {
      const projectImage = document.querySelectorAll(
        '.src-sites-candt-components-SingleBlowout-singleBlowout__projectImage'
      );
      [...projectImage].map(item => item.setAttribute('aria-hidden', 'true'));
    }
  }, []);

  return (
    <section ref={stickyRef} className={`grid-container ${styles.singleBlowout}`}>
      {backgroundAsset && backgroundAsset.length ? (
        <Image
          src={get(backgroundAsset, '[0].url')}
          alt={get(backgroundAsset, '[0].title')}
          className={styles.singleBlowout__background}
          lazyload={false}
        />
      ) : null}
      <Image
        src={get(largeProjectAsset, '[0].url')}
        alt={get(largeProjectAsset, '[0].title')}
        className={styles.singleBlowout__projectImage}
        sizes={imageSizes}
      />
      <div className={styles.singleBlowout__content}>
        <span className={styles.singleBlowout__eyebrow}>{clientName} &mdash;</span>
        <Link
          to={{ pathname: `/${get(caseStudyLink, '[0].uri')}` }}
          title={get(caseStudyLink, '[0].title')}>
          <h2
            className={styles.singleBlowout__title}
            dangerouslySetInnerHTML={{
              __html: stripParagraphTag(get(caseStudyTitle, 'content'))
            }}
          />
        </Link>
        <p className={styles.singleBlowout__description}>{description}</p>
        <Link
          to={{ pathname: `/${get(caseStudyLink, '[0].uri')}` }}
          title={get(caseStudyLink, '[0].title')}
          aria-label={`Read more about ${get(caseStudyLink, '[0].title')}`}>
          <span className={styles.singleBlowout__cta}>Read More</span>
        </Link>
        {bulletedList && bulletedList.length ? (
          <ul className={styles.singleBlowout__bulletList}>
            {bulletedList.map((item, i) => (
              <li key={i} className={styles.singleBlowout__listItem}>
                <span>{get(item, 'itemText')}</span>
              </li>
            ))}
          </ul>
        ) : null}
        {metric && metric[0] ? (
          <div
            aria-label={`${get(metric, '[0].metricNumber')} ${get(metric, '[0].metricTitle')}`}
            role="note"
            className={styles.singleBlowout__metric}>
            <p aria-hidden="true" className={styles.singleBlowout__metricTitle}>
              {get(metric, '[0].metricTitle')}
            </p>
            <h3 aria-hidden="true" className={metricNumberStyles}>
              {get(metric, '[0].metricNumber')}
            </h3>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default SingleBlowout;
