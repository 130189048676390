import React, { useState, useRef, useEffect, useCallback } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import Swipe from 'react-easy-swipe';

// components
import Card from 'components/Cards';
import Video from 'components/Video';

// helpers
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';
import isInViewport from 'helpers/isInViewport';
import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';

// hooks
import useBreakpointKey, { TABLET, SMALL_DESKTOP } from '../../hooks/useBreakpointKey';
import useDebounce from '../../hooks/useDebounce';

import styles from './styles.scss';

const getVideo = data =>
  get(data, 'videoType') === 'embedVideos' ? get(data, 'video') : get(data, 'videoAsset[0]');

const SizzleVideoContainer = props => {
  const { data, stickyRef } = props;

  const breakpointKey = useBreakpointKey();
  const debounce = useDebounce();
  const [playing, setPlaying] = useState(false);

  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);
  const videoContainerRef = useRef();
  const videoRef = useRef();
  const cardsRef = useRef();

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCardWidth = useCallback(() => cardsRef.current.children[0].clientWidth, []);

  const currentIndex = useCallback(
    (idx = 0) => {
      const maxCards = 2;

      let card = 0;

      if (idx < 0) {
        card = 0;
      } else if (idx > maxCards - 1) {
        card = maxCards - 1;
      } else if (breakpointKey >= TABLET && idx >= maxCards - 1) {
        card = idx - 1;
      } else {
        card = idx;
      }

      return card;
    },
    [breakpointKey]
  );

  const goToCard = useCallback(
    (idx = 0) => {
      const id = currentIndex(idx);
      index.current = id;
      originalX.current = id * getCardWidth() * -1;
      const offsetPos = getCardWidth() / 10;
      cardsRef.current.style.transform = `translateX(${
        originalX.current + index.current * offsetPos
      }px)`;
    },
    [currentIndex]
  );

  const onSwipeStart = useCallback(() => {
    if (breakpointKey >= TABLET) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.sizzleVideo__cards}--swipe`);
  }, [breakpointKey]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpointKey >= SMALL_DESKTOP) {
        return false;
      }

      if (!scrollDirection.current) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirection.current = 'vertical';
        } else {
          scrollDirection.current = 'horizontal';
        }
      }

      if (scrollDirection.current === 'horizontal') {
        currentX.current = e.x;
        let newX = e.x + originalX.current;
        if ((index.current === 0 && e.x > 0) || (index.current === 1 && e.x < 0)) {
          newX = originalX.current;
        }
        cardsRef.current.style.transform = `translateX(${newX}px)`;
        return true;
      }
      return false;
    },
    [breakpointKey]
  );

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (breakpointKey >= SMALL_DESKTOP) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.sizzleVideo__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 5) {
      goToCard((index.current += direction));
    } else {
      goToCard(index.current);
    }
  }, [breakpointKey, goToCard]);

  useEffect(() => {
    const resizeHandler = () => {
      if (breakpointKey >= TABLET) {
        cardsRef.current.classList.remove(`${styles.sizzleVideo__cards}--swipe`);
        cardsRef.current.style.transform = '';

        return;
      }

      if (Math.abs(getCurrentTranslateX()) > 0) {
        cardsRef.current.style.transform = `translateX(-${getCardWidth()}px)`;
      }
    };
    const resizeEvent = EventHandler.subscribe(EVENT.RESIZE, debounce(resizeHandler, 500));

    return () => EventHandler.unsubscribe(resizeEvent);
  }, [breakpointKey]);

  useEffect(() => {
    const scrollHandler = () => {
      if (videoContainerRef.current) {
        if (isInViewport(videoContainerRef.current)) {
          setPlaying(true);
        } else {
          setPlaying(false);
        }
      }
    };

    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, debounce(scrollHandler, 300));

    return () => EventHandler.unsubscribe(scrollEvent);
  }, []);

  const video = getVideo(data);

  const { caption, description, cta, sizzleCards } = data;

  return (
    <section ref={stickyRef} className={styles.sizzleVideo}>
      <h2
        className={styles.sizzleVideo__title}
        dangerouslySetInnerHTML={{ __html: stripParagraphTag(get(caption, 'content')) }}
      />
      <div className={styles.sizzleVideo__container}>
        <div className={styles.sizzleVideo__description}>
          <p>{description}</p>
          {get(cta, '[0].title', '') !== '' || get(cta, '[0].uri', '') !== '' ? (
            <span>
              <Link
                to={`/${get(cta, '[0].uri')}`}
                className={styles.sizzleVideo__cta}
                alt={get(cta, '[0].title')}>
                Read More
              </Link>
            </span>
          ) : null}
        </div>
        <div className={styles.sizzleVideo__video} ref={videoContainerRef}>
          <div>
            <LazyLoad height="100%" offset={1000} once>
              <Video
                src={get(video, 'url')}
                playsinline={true}
                muted={true}
                autoPlay={true}
                loop={true}
                onEnded={() => videoRef.current.load()}
                togglePlay={playing}
              />
            </LazyLoad>
          </div>
        </div>
      </div>
      <Swipe
        ref={el => el && (cardsRef.current = el.swiper)}
        className={styles.sizzleVideo__cards}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}>
        <Card
          {...get(sizzleCards, '[0].entryCard1[0]')}
          size="floating|standard"
          className={styles.sizzleVideo__card}
          lazyload={false}
          zoom={true}
        />
        <Card
          {...get(sizzleCards, '[0].entryCard2[0]')}
          size="floating|standard"
          className={styles.sizzleVideo__card}
          lazyload={false}
          zoom={true}
        />
      </Swipe>
    </section>
  );
};

export default SizzleVideoContainer;
