import React, { useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Swipe from 'react-easy-swipe';

// components
import Card from 'components/Cards';
import cardStyles from 'components/Cards/styles.scss';

// helpers
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';

// hooks
import useBreakpointKey, { TABLET, SMALL_DESKTOP } from '../../hooks/useBreakpointKey';

import styles from './styles.scss';

const LinearProject = props => {
  const { data, stickyRef } = props;
  const breakpointKey = useBreakpointKey();
  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);
  const cardsRef = useRef();

  const { caption, description, cards = [], readMoreCta } = data;

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCurrentMargin = useCallback(() => {
    const currentMargin = window.getComputedStyle(cardsRef.current.children[1]).marginLeft;
    return currentMargin !== '0px' ? parseFloat(currentMargin) : 0;
  }, []);

  const getCardWidth = useCallback(
    () => cardsRef.current.children[0].clientWidth + getCurrentMargin(),
    []
  );

  const currentIndex = useCallback(
    (idx = 0) => {
      let card = 0;

      if (idx < 0) {
        card = 0;
      } else if (idx > cards.length - 1) {
        card = cards.length - 1;
      } else if (breakpointKey >= TABLET && idx >= cards.length - 1) {
        card = idx - 1;
      } else {
        card = idx;
      }

      return card;
    },
    [breakpointKey, cards.length]
  );

  const goToCard = useCallback(
    (idx = 0) => {
      const id = currentIndex(idx);
      index.current = id;
      originalX.current = id * getCardWidth() * -1;
      cardsRef.current.style.transform = `translateX(${originalX.current}px)`;
    },
    [currentIndex]
  );

  const onSwipeStart = useCallback(() => {
    if (breakpointKey >= SMALL_DESKTOP) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.linearProject__cards}--swipe`);
  }, [breakpointKey]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpointKey >= SMALL_DESKTOP) {
        return false;
      }
      if (!scrollDirection.current) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirection.current = 'vertical';
        } else {
          scrollDirection.current = 'horizontal';
        }
      }

      if (scrollDirection.current === 'horizontal') {
        currentX.current = e.x;
        let newX = e.x + originalX.current;
        if ((index.current === 0 && e.x > 0) || (index.current === cards.length && e.x < 0)) {
          newX = originalX.current;
        }
        cardsRef.current.style.transform = `translateX(${newX}px)`;
        return true;
      }
      return false;
    },
    [breakpointKey, cards.length]
  );

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (breakpointKey >= SMALL_DESKTOP) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.linearProject__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 4) {
      goToCard((index.current += direction));
    } else {
      goToCard(index.current);
    }
  }, [breakpointKey, goToCard]);

  useEffect(() => {
    if (breakpointKey >= SMALL_DESKTOP) {
      cardsRef.current.classList.remove(`${styles.linearProject__cards}--swipe`);
      cardsRef.current.style.transform = '';
    } else {
      goToCard(index.current);
    }
  }, [breakpointKey, goToCard]);

  return (
    <section ref={stickyRef} className={styles.linearProject}>
      <div className={styles.linearProject__content}>
        <h2
          className={styles.linearProject__title}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(get(caption, 'content')) }}
        />
        <div className={styles.linearProject__description}>
          <p>{description}</p>
          {readMoreCta && readMoreCta.length ? (
            <span>
              <Link
                to={`/${get(readMoreCta, '[0].uri')}`}
                className={styles.linearProject__cta}
                title={get(readMoreCta, '[0].title')}>
                Read More
              </Link>
            </span>
          ) : null}
        </div>
      </div>
      <Swipe
        tagName="ul"
        ref={el => el && (cardsRef.current = el.swiper)}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        className={styles.linearProject__cards}>
        {cards.map((cardItem, key) => (
          <li key={`linearProject${key}`} className={styles.linearProject__card}>
            <Card
              {...get(cardItem, 'card[0]')}
              size="standard"
              className={cardStyles.card__linear_project}
              key={key}
              lazyload={false}
              zoom={true}
            />
          </li>
        ))}
      </Swipe>
    </section>
  );
};

export default LinearProject;
