import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import Image from 'components/Image';
import Video from 'components/Video';

import { get } from 'helpers/utilities';

// helpers
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const FullWidthVideo = ({ data }) => {
  const { desktopTabletVideo, mobileVideo, desktopTabletPoster, mobilePoster } = data;
  const breakpoint = useBreakpoint();
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const fullWidthVideoClass = cx('fullWidthVideo', {
    'fullWidthVideo--is-played': isPlayed
  });

  const togglePlay = useCallback(() => {
    setPlaying(!playing);

    setIsPlayed(true);
  }, [playing]);

  const toggleMute = useCallback(() => setMuted(!muted), [muted]);

  const mobileVideoAsset = get(mobileVideo, '[0]');
  const mobilePosterAsset = get(mobilePoster, '[0]');
  const desktopTabletPosterAsset = get(desktopTabletPoster, '[0]');

  const videoAsset =
    breakpoint === 'mobile' && mobileVideoAsset ? mobileVideoAsset : get(desktopTabletVideo, '[0]');

  const imageAsset =
    breakpoint === 'mobile' && mobilePosterAsset ? mobilePosterAsset : desktopTabletPosterAsset;

  return (
    <section className={fullWidthVideoClass}>
      {imageAsset && (
        <div className={styles.fullWidthVideo__image}>
          <Image src={imageAsset.url} alt={imageAsset.title} lazyload={false} sizes={imageSizes} />
        </div>
      )}
      <Video
        className={styles.fullWidthVideo__video}
        autoPlay={playing}
        muted={muted}
        loop={true}
        src={videoAsset.url}
        title={videoAsset.title}
        togglePlay={playing}
      />
      <div className={styles.fullWidthVideo__controls}>
        <button onClick={togglePlay}>{playing ? 'Pause' : 'Play'}</button>
        <button onClick={toggleMute}>{muted ? 'Unmute Sound' : 'Mute Sound'}</button>
      </div>
    </section>
  );
};

export default FullWidthVideo;
