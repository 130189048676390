import { useQuery } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import theThingsWeMake from 'queries/theThingsWeMake';
import { components as twmcomponents } from 'queries/twm_components';

const useTaxonomies = () => {
  const query = theThingsWeMake.component(twmcomponents.filters.component);

  const { data } = useQuery(query);
  const taxonomies = get(data, 'entry.filters[0]tags', []);

  return taxonomies;
};

export default useTaxonomies;
