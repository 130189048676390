import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import { stripParagraphTag } from 'helpers/text';
import { pickArticleHeaderContent } from 'queries/articleHeader';
import { pickFloatingOrStandardCard } from 'queries/cardArtwork';

import styles from './styles.scss';

const imageSizes = {
  mobile: 730,
  tablet: 550,
  smallDesktop: 280,
  largeDesktop: 550
};

const HalfUpModule = ({ data, stickyRef }) => {
  const { articleData, projectData } = data;
  const isArticleCustomTitle = get(articleData, '[0].articleTitleSelection[0].customTitle');
  const articleTitle = isArticleCustomTitle
    ? isArticleCustomTitle.content
    : pickArticleHeaderContent(get(articleData, '[0].articleEntry[0]'));

  return (
    <section ref={stickyRef} className={styles.halfUpModule}>
      {projectData.map((project, index) => {
        const cardType = Object.hasOwn(get(project, 'projectEntry[0]'), 'cardArticle')
          ? 'cardArticle'
          : 'cardProject';
        const isProjectCustomTitle = get(project, 'projectTitleSelection[0].customTitle');
        const projectTitle = isProjectCustomTitle
          ? isProjectCustomTitle.content
          : pickArticleHeaderContent(get(project, 'projectEntry[0]'));

        return (
          pickFloatingOrStandardCard(get(project, `projectEntry[0].${cardType}[0]`, {})) && (
            <div className={styles.halfUpModule__feature} key={`halfup-${index}`}>
              <span className={styles.halfUpModule__label}>
                {cardType === 'cardProject' && (
                  <strong>{get(project, 'projectEntry[0].cardProject[0].caption', '')}</strong>
                )}
                {cardType === 'cardArticle' && (
                  <strong>
                    {get(project, 'projectEntry[0].cardArticle[0].caption.content', '').replace(
                      /<\/?[^>]+(>|$)/g,
                      ''
                    )}
                  </strong>
                )}
                <span>| {get(project, `projectEntry[0].${cardType}[0].description`, '')}</span>
              </span>
              <h3
                className={styles.halfUpModule__title}
                dangerouslySetInnerHTML={{ __html: stripParagraphTag(projectTitle) }}
              />
              <figure className={styles.halfUpModule__body}>
                <Link
                  to={{ pathname: `/${get(project, 'projectEntry[0].uri', '#')}` }}
                  className={styles.halfUpModule__image}>
                  <Image
                    src={
                      pickFloatingOrStandardCard(get(project, `projectEntry[0].${cardType}[0]`, {}))
                        .url
                    }
                    alt={projectTitle}
                    lazyload={false}
                    sizes={imageSizes}
                  />
                </Link>
                <figcaption className={styles.halfUpModule__description}>
                  <p>{get(project, 'projectDescription', '')}</p>
                  <span>
                    <Link
                      to={{ pathname: `/${get(project, 'projectEntry[0].uri', '#')}` }}
                      className={styles.halfUpModule__cta}>
                      Read More
                    </Link>
                  </span>
                </figcaption>
              </figure>
            </div>
          )
        );
      })}

      {projectData.length === 1 ? (
        <div className={styles.halfUpModule__feature}>
          <h3
            className={styles.halfUpModule__title}
            dangerouslySetInnerHTML={{ __html: stripParagraphTag(articleTitle) }}
          />
          <figure className={styles.halfUpModule__body}>
            {get(articleData, '[0].articleAsset[0]') ? (
              <Link
                to={{ pathname: `/${get(articleData, '[0].articleEntry[0].uri', '#')}` }}
                className={styles.halfUpModule__image}>
                <Image
                  src={get(articleData, '[0].articleAsset[0].url', '')}
                  alt={articleTitle}
                  lazyload={false}
                  sizes={imageSizes}
                />
              </Link>
            ) : null}
            <figcaption className={styles.halfUpModule__description}>
              <p>{get(articleData, '[0].articleDescription', '')}</p>
              <span>
                <Link
                  to={{ pathname: `/${get(articleData, '[0].articleEntry[0].uri', '#')}` }}
                  className={styles.halfUpModule__cta}>
                  Read More
                </Link>
              </span>
            </figcaption>
          </figure>
        </div>
      ) : null}
    </section>
  );
};

export default HalfUpModule;
