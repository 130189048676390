import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Swipe from 'react-easy-swipe';
import classNames from 'classnames/bind';
import { struct } from 'superstruct';

// components
import Image from 'components/Image';
import safeRender from 'components/hocs/safeRender';

// helpers
import deviceInfo from 'helpers/deviceInfo';
import { wysiwygStruct } from 'helpers/structure';
import { get } from 'helpers/utilities';

// hooks
import useBreakpoint from '../../hooks/useBreakpoint';
import useBreakpointKey, { SMALL_DESKTOP, TABLET } from '../../hooks/useBreakpointKey';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const AwardsModule = props => {
  const { data = {}, stickyRef, awardsModuleTitleClass } = props;
  const breakpoint = useBreakpoint();
  const breakpointKey = useBreakpointKey();

  const [current, setCurrent] = useState(1);
  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);

  const cardsRef = useRef();

  const { caption, awardCards = [] } = data;

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCurrentMargin = useCallback(() => {
    const currentMargin = window.getComputedStyle(cardsRef.current.children[1]).marginLeft;
    return currentMargin !== '0px' ? parseFloat(currentMargin) : 0;
  }, []);

  const getCardWidth = useCallback(
    () => cardsRef.current.children[0].clientWidth + getCurrentMargin(),
    []
  );

  const currentIndex = useCallback(
    (idx = 0) => {
      let card = 0;

      if (index < 0) {
        card = 0;
      } else if (idx > awardCards.length - 1) {
        card = awardCards.length - 1;
      } else {
        card = idx;
      }

      return card;
    },
    [awardCards.length]
  );

  const slideToCard = useCallback(
    (idx = 0) => {
      const id = currentIndex(idx);
      const position = id * getCardWidth() * -1;
      index.current = id;
      originalX.current = position;
      cardsRef.current.style.transform = `translateX(${originalX.current}px)`;
      setCurrent(index.current);
    },
    [currentIndex]
  );

  const onMouseEnterHandler = useCallback(
    idx => {
      if (deviceInfo.isTouch && breakpointKey <= SMALL_DESKTOP) {
        return;
      }

      setCurrent(idx);
    },
    [breakpointKey]
  );

  const onMouseLeaveHandler = useCallback(() => {
    if (deviceInfo.isTouch && breakpointKey <= SMALL_DESKTOP) {
      return;
    }

    setCurrent(1);
  }, [breakpointKey]);

  const onSwipeStart = useCallback(() => {
    if (breakpointKey >= TABLET) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.awardsModule__cards}--swipe`);
  }, [breakpointKey]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpointKey >= TABLET) {
        return false;
      }

      if (!scrollDirection.current) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirection.current = 'vertical';
        } else {
          scrollDirection.current = 'horizontal';
        }
      }

      if (scrollDirection.current === 'horizontal') {
        currentX.current = e.x;
        cardsRef.current.style.transform = `translateX(${e.x + originalX.current}px)`;
        return true;
      }
      return false;
    },
    [breakpointKey]
  );

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (breakpointKey >= TABLET) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.awardsModule__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 4) {
      slideToCard((index.current += direction));
    } else {
      slideToCard(index.current);
    }
  }, [breakpointKey, slideToCard]);

  useEffect(() => {
    const poller = setInterval(() => {
      if (deviceInfo.isTouch && breakpointKey >= TABLET && breakpointKey <= SMALL_DESKTOP) {
        index.current = current + 1;
        const x = index.current >= awardCards.length ? 0 : index.current;
        setCurrent(x);
      }
    }, 3000);
    if (breakpoint === 'mobile') setCurrent(0);
    return () => {
      clearInterval(poller);
    };
  }, [breakpoint, breakpointKey, awardCards.length]);

  return (
    <section ref={stickyRef} className={styles.awardsModule}>
      <div className={styles.awardsModule__background}>
        {awardCards.map((award, key) =>
          get(award, 'awardBackground[0].url') ? (
            <Image
              key={key}
              src={get(award, 'awardBackground[0].url')}
              alt={get(award, 'awardBackground[0].title')}
              lazyload={false}
              style={{ objectPosition: 'center center' }}
              className={cx('awardsModule__background__image', {
                'awardsModule__background__image--visible': key === current
              })}
            />
          ) : null
        )}
      </div>
      <h2
        className={`${styles.awardsModule__title} ${awardsModuleTitleClass}`}
        dangerouslySetInnerHTML={{ __html: get(caption, 'content') }}
      />
      <Swipe
        tagName="ul"
        ref={el => el && (cardsRef.current = el.swiper)}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        className={styles.awardsModule__cards}>
        {awardCards.map((award, key) => (
          <li
            key={key}
            className={cx('awardsModule__card', {
              'awardsModule__card--active': key === current
            })}
            onMouseEnter={() => onMouseEnterHandler(key)}
            onMouseLeave={onMouseLeaveHandler}>
            <Link
              to={{ pathname: `/${get(award, 'awardLink[0].uri')}` }}
              title={`${get(award, 'awardLink[0].title')} - ${get(award, 'awardName')}`}
              className={styles.awardsModule__card__link}
            />
            <div className={styles.awardsModule__card__container}>
              <div className={styles.awardsModule__card__content}>
                {get(award, 'awardBrand') ? (
                  <h3
                    className={styles.awardsModule__card__brand}
                    dangerouslySetInnerHTML={{ __html: get(award, 'awardBrand') }}
                  />
                ) : null}
                {get(award, 'awardName') ? (
                  <h4
                    className={styles.awardsModule__card__name}
                    dangerouslySetInnerHTML={{ __html: get(award, 'awardName') }}
                  />
                ) : null}
                {get(award, 'awardCategory') ? (
                  <h5
                    className={styles.awardsModule__card__category}
                    dangerouslySetInnerHTML={{ __html: get(award, 'awardCategory') }}
                  />
                ) : null}
              </div>
              {get(award, 'awardLogo').length ? (
                <div className={styles.awardsModule__card__award} aria-hidden="true">
                  {get(award, 'awardLogo[0].url') ? (
                    <Image
                      src={get(award, 'awardLogo[0].url')}
                      alt={get(award, 'awardLogo[0].title')}
                      lazyload={false}
                      sizes={{ largeDesktop: 340 }}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </li>
        ))}
      </Swipe>
    </section>
  );
};

export default safeRender(
  struct.partial({
    caption: wysiwygStruct,
    awardCards: struct.list(['any'])
  })
)(AwardsModule);
