import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import { isStructValid } from 'helpers/structure';
import getFeatureModules from './getFeatureModules';

import styles from './styles.scss';

const FeatureModule = ({ handleName, featureComponent, query, index, struct }) => {
  const Component = featureComponent;
  const { loading, error, data } = useQuery(query);

  if (loading || error) return null;

  let entryFeaturesModule = null;

  const typeName = get(data, 'entry.__typename');
  const articleBodyFeaturesModule = get(data, 'entry.articleBody');

  if (
    typeName === 'SinglePage' &&
    articleBodyFeaturesModule &&
    articleBodyFeaturesModule.length > 0
  ) {
    const articleBodyFeatures = articleBodyFeaturesModule.filter(
      content => content.featuresModule && content.featuresModule.length > 0
    );
    entryFeaturesModule =
      articleBodyFeatures && articleBodyFeatures.length > 0
        ? articleBodyFeatures[0].featuresModule
        : null;
  } else {
    entryFeaturesModule = get(data, 'entry.featuresModule');
  }

  if (!entryFeaturesModule) return null;

  const contentData = get(entryFeaturesModule, `[${index}][${handleName}]`, []);
  if (!struct) return null;
  if (!isStructValid(struct, contentData[0])) return null;

  return <Component data={contentData[0]} />;
};

const Features = ({ features = [], componentQuery, isArticleBody = false }) => {
  if (!features.length) return null;
  const featureModules = getFeatureModules(componentQuery);
  const featureItems = {
    FeaturesLinearProjectCollection: 'FeaturesArticleBodyLinearProjectCollection',
    FeaturesSingleBlowout: 'FeaturesArticleBodySingleBlowout',
    FeaturesHalfUp: 'FeaturesArticleBodyHalfUp',
    FeaturesSizzleVideoCollection: 'FeaturesArticleBodySizzleVideoCollection',
    FeaturesScrollJackProjectTour: 'FeaturesArticleBodyScrollJackProjectTour',
    FeaturesEditorialProject3Up: 'FeaturesArticleBodyEditorialProject3Up',
    FeaturesAwards: 'FeaturesArticleBodyAwards',
    FeaturesEvents: 'FeaturesArticleBodyEvents',
    FeaturesBrandSpotlight: 'FeaturesArticleBodyBrandSpotlight',
    FeaturesFullWidthVideo: 'FeaturesArticleBodyFullWidthVideo'
  };

  return (
    <section
      className={`${styles.featuresModuleContainer} ${
        isArticleBody ? styles.featuresModuleContainer__articleBodyFeatureModules : ''
      }`}
      data-full-width={isArticleBody}>
      <div className={styles.featuresModuleContainer__lineBackground} />
      {features.map((component, index) => {
        let name = get(component, '__typename');

        if (isArticleBody) {
          name = featureItems[name];
        }
        const featureModule = featureModules[name];
        if (!featureModule) return null;

        return <FeatureModule {...featureModule} index={index} key={index} />;
      })}
    </section>
  );
};

export default Features;
