import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';

import deviceInfo from 'helpers/deviceInfo';
import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';
import { breakpoints } from 'config/constants';

// components
import HomeNavigation from 'components/HomeNavigation';
import Meta from 'components/Meta';

import NavigationThemeSetter from 'components/NavigationThemeSetter';
import Features from 'components/Features';
import home from 'queries/home';
import useHomeContent from '../../hooks/useHomeContent';
import useGlobalContent from '../../hooks/useGlobalContent';
import useNavigation from '../../hooks/useNavigation';

// queries

const Home = ({ active }) => {
  const homeContent = useHomeContent();
  const globalContent = useGlobalContent();
  const gtmData = buildGTMData(homeContent);
  const {
    state: { activeLink },
    setShowPrimaryLinks,
    setShowLogo
  } = useNavigation();

  const [current, setCurrent] = useState(0);
  const poller = useRef(null);

  const poll = useCallback(() => {
    if (window.innerWidth >= breakpoints.smallDesktop && !deviceInfo.isMobile) {
      return;
    }

    setCurrent(prevCurrent => (prevCurrent >= 4 ? 0 : prevCurrent + 1));
  });

  useEffect(() => {
    setShowPrimaryLinks(!active);
    setShowLogo(!active);
  }, [active]);

  useEffect(
    () => () => {
      clearInterval(poller.current);

      setShowPrimaryLinks(true);
      setShowLogo(true);
    },
    []
  );

  if (!homeContent || !globalContent) return null;

  const defaultVideoData = get(homeContent.homeNavigationDefaultVideo, '[0]', {});
  const homeNavigationItems = homeContent.homeNavigation || [];
  const watermark = homeContent.watermark || {};
  const recentNews = homeContent.recentNews || [];
  const featuresModule = homeContent.featuresModule || [];

  const noHome = activeLink === '/search' || activeLink === '/contact';

  const metaDetails = {
    metaSection: get(homeContent, 'socialMetaSection[0]'),
    entryTitle: get(homeContent, 'title')
  };
  return (
    !noHome && (
      <>
        <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
        <NavigationThemeSetter navTheme="header">
          <HomeNavigation
            data={[defaultVideoData, ...homeNavigationItems]}
            recentNews={recentNews}
            hasFeatures={featuresModule.length > 0}
            setVideoVisibilityFilter={index => {
              // clear interval on mouse enter
              clearInterval(poller.current);

              setCurrent(index);

              // set poller on mouse out
              if (index === current) {
                poller.current = setInterval(poll, 2000);
              }
            }}
            current={current}
            watermark={watermark}
            initializePoll={() => {
              poller.current = setInterval(poll, 2000);
            }}
          />
          <Features features={featuresModule} componentQuery={home.component} />
        </NavigationThemeSetter>
      </>
    )
  );
};

export default Home;
