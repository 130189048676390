import HalfUpModule from 'components/HalfUpModule';
import BrandSpotlight from 'components/BrandSpotlight';
import SingleBlowout from 'components/SingleBlowout';
import EventsModule from 'components/EventsModule';
import AwardsModule from 'components/AwardsModule';
import EditorialProject from 'components/EditorialProject';
import LinearProject from 'components/LinearProject';
import ScrollJackProject from 'components/ScrollJackProject';
import SizzleVideo from 'components/SizzleVideo';
import FullWidthVideo from 'components/FullWidthVideo';

import { components as twmcomponents, features as twmfeatures } from '../../queries/twm_components';

import {
  halfUpModuleStruct,
  linearProjectStruct,
  editorialProjectStruct,
  brandSpotlightStruct,
  sizzleVideoStruct,
  awardsStruct,
  singleBlowoutStruct,
  scrollJackProjectStruct,
  eventsStruct,
  fullWidthVideoStruct
} from './featureModulesStruct';

export default componentQuery => ({
  FeaturesHalfUp: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.halfUp)),
    featureComponent: HalfUpModule,
    handleName: 'halfUpFeatures',
    struct: halfUpModuleStruct
  },
  FeaturesLinearProjectCollection: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.linearProject)),
    featureComponent: LinearProject,
    handleName: 'linearProject',
    struct: linearProjectStruct
  },
  FeaturesEditorialProject3Up: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.editorialProject)),
    featureComponent: EditorialProject,
    handleName: 'editorialProject',
    struct: editorialProjectStruct
  },
  FeaturesBrandSpotlight: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.brandSpotlight)),
    featureComponent: BrandSpotlight,
    handleName: 'brandSpotlight',
    struct: brandSpotlightStruct
  },
  FeaturesSizzleVideoCollection: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.sizzleVideo)),
    featureComponent: SizzleVideo,
    handleName: 'sizzleVideo',
    struct: sizzleVideoStruct
  },
  FeaturesAwards: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.awards)),
    featureComponent: AwardsModule,
    handleName: 'awards',
    struct: awardsStruct
  },
  FeaturesSingleBlowout: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.singleBlowout)),
    featureComponent: SingleBlowout,
    handleName: 'singleBlowout',
    struct: singleBlowoutStruct
  },
  FeaturesScrollJackProjectTour: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.scrollJackProject)),
    featureComponent: ScrollJackProject,
    handleName: 'scrollJackProject',
    struct: scrollJackProjectStruct
  },
  FeaturesEvents: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.events)),
    featureComponent: EventsModule,
    handleName: 'eventsModule',
    struct: eventsStruct
  },
  FeaturesFullWidthVideo: {
    query: componentQuery(twmcomponents.featuresModule.component(twmfeatures.fullWidthVideo)),
    featureComponent: FullWidthVideo,
    handleName: 'fullWidthVideo',
    struct: fullWidthVideoStruct
  },
  FeaturesArticleBodyLinearProjectCollection: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.linearProject)
    ),
    featureComponent: LinearProject,
    handleName: 'linearProject',
    struct: linearProjectStruct
  },
  FeaturesArticleBodySingleBlowout: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.singleBlowout)
    ),
    featureComponent: SingleBlowout,
    handleName: 'singleBlowout',
    struct: singleBlowoutStruct
  },
  FeaturesArticleBodyHalfUp: {
    query: componentQuery(twmcomponents.articleBodyFeaturesModule.component(twmfeatures.halfUp)),
    featureComponent: HalfUpModule,
    handleName: 'halfUpFeatures',
    struct: halfUpModuleStruct
  },
  FeaturesArticleBodySizzleVideoCollection: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.sizzleVideo)
    ),
    featureComponent: SizzleVideo,
    handleName: 'sizzleVideo',
    struct: sizzleVideoStruct
  },
  FeaturesArticleBodyScrollJackProjectTour: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.scrollJackProject)
    ),
    featureComponent: ScrollJackProject,
    handleName: 'scrollJackProject',
    struct: scrollJackProjectStruct
  },
  FeaturesArticleBodyEditorialProject3Up: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.editorialProject)
    ),
    featureComponent: EditorialProject,
    handleName: 'editorialProject',
    struct: editorialProjectStruct
  },
  FeaturesArticleBodyAwards: {
    query: componentQuery(twmcomponents.articleBodyFeaturesModule.component(twmfeatures.awards)),
    featureComponent: AwardsModule,
    handleName: 'awards',
    struct: awardsStruct
  },
  FeaturesArticleBodyEvents: {
    query: componentQuery(twmcomponents.articleBodyFeaturesModule.component(twmfeatures.events)),
    featureComponent: EventsModule,
    handleName: 'eventsModule',
    struct: eventsStruct
  },
  FeaturesArticleBodyBrandSpotlight: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.brandSpotlight)
    ),
    featureComponent: BrandSpotlight,
    handleName: 'brandSpotlight',
    struct: brandSpotlightStruct
  },
  FeaturesArticleBodyFullWidthVideo: {
    query: componentQuery(
      twmcomponents.articleBodyFeaturesModule.component(twmfeatures.fullWidthVideo)
    ),
    featureComponent: FullWidthVideo,
    handleName: 'fullWidthVideo',
    struct: fullWidthVideoStruct
  }
});
