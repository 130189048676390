import React from 'react';
import { stripParagraphTag } from 'helpers/text';
import classNames from 'classnames/bind';
import SpecialCardGrid from 'components/SpecialCardGrid';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const features = {
  specialized1: [1, 4, 5],
  specialized2: [1, 4, 8],
  simplified: []
};

const CardGridTitle = ({ isSaysALot, titleStyles, title }) => {
  if (isSaysALot) {
    return (
      <header className="grid-container">
        <h1
          className={titleStyles}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(title) }}
        />
      </header>
    );
  }
  return (
    <header className={styles.cardGridComponent__heading}>
      <h1 className={titleStyles}>
        <span
          className={styles.cardGridComponent__titleInner}
          dangerouslySetInnerHTML={{ __html: `${title}` }}
        />
        <span className={styles.cardGridComponent__titleShadow1} aria-hidden="true">
          <span
            className={styles.cardGridComponent__titleShadow1__text}
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </span>
        <span className={styles.cardGridComponent__titleShadow2} aria-hidden="true">
          <span
            className={styles.cardGridComponent__titleShadow2__text}
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </span>
        <span className={styles.cardGridComponent__titleShadow3} aria-hidden="true">
          <span
            className={styles.cardGridComponent__titleShadow3__text}
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </span>
      </h1>
    </header>
  );
};

const SpecialCardGridContainer = ({
  containerStyles,
  titleStyles,
  title,
  isSaysALot,
  layout,
  cards,
  transparentCard,
  className
}) => (
  <section className={`${containerStyles} ${className}`}>
    {title && <CardGridTitle isSaysALot={isSaysALot} titleStyles={titleStyles} title={title} />}
    <SpecialCardGrid
      type={layout}
      cards={cards}
      animated={true}
      features={features[layout]}
      transparentCard={transparentCard}
    />
  </section>
);

const CardGridComponent = props => {
  const { hasAnimation, titleStyle, isSaysALot } = props;

  const containerStyles = cx('cardGridComponent', {
    'cardGridComponent--hasAnimation': hasAnimation
  });
  const titleStyles = cx('cardGridComponent__title', {
    'cardGridComponent__title--no-slash': titleStyle === 'no-slash',
    'cardGridComponent__title--saysALot': isSaysALot === true
  });

  return (
    <SpecialCardGridContainer
      {...props}
      containerStyles={containerStyles}
      titleStyles={titleStyles}
    />
  );
};

export default CardGridComponent;
