import React, { useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Swipe from 'react-easy-swipe';

// components
import Card from 'components/Cards';

// helpers
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';

// hooks
import useBreakpoinKey, { TABLET, SMALL_DESKTOP } from '../../hooks/useBreakpointKey';

import styles from './styles.scss';

const EditorialProject = props => {
  const { data, stickyRef } = props;
  const breakpointKey = useBreakpoinKey();
  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);

  const cardsRef = useRef();

  const { caption, description, cta, cards = [] } = data;

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCurrentMargin = useCallback(() => {
    const currentMargin = window.getComputedStyle(cardsRef.current.children[1]).marginLeft;
    return currentMargin !== '0px' ? parseFloat(currentMargin) : 0;
  }, []);

  const getCardWidth = useCallback(
    () => cardsRef.current.children[0].clientWidth + getCurrentMargin(),
    []
  );

  const currentIndex = useCallback(
    (idx = 0) => {
      let card = 0;

      if (idx < 0) {
        card = 0;
      } else if (idx > cards.length - 1) {
        card = cards.length - 1;
      } else if (breakpointKey >= TABLET && idx >= cards.length - 1) {
        card = idx - 1;
      } else {
        card = idx;
      }

      return card;
    },
    [breakpointKey, cards.length]
  );

  const goToCard = useCallback(
    (idx = 0) => {
      const id = currentIndex(idx);
      const position = id * getCardWidth() * -1;
      index.current = id;
      originalX.current = position;
      cardsRef.current.style.transform = `translateX(${originalX.current}px)`;
    },
    [currentIndex]
  );

  const onSwipeStart = useCallback(() => {
    if (breakpointKey >= SMALL_DESKTOP) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.editorialProject__content__cards}--swipe`);
  }, [breakpointKey]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpointKey >= SMALL_DESKTOP) {
        return false;
      }

      if (!scrollDirection.current) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirection.current = 'vertical';
        } else {
          scrollDirection.current = 'horizontal';
        }
      }

      if (scrollDirection.current === 'horizontal') {
        currentX.current = e.x;
        let newX = e.x + originalX.current;
        if ((index.current === 0 && e.x > 0) || (index.current === cards.length && e.x < 0)) {
          newX = originalX.current;
        }
        cardsRef.current.style.transform = `translateX(${newX}px)`;
        return true;
      }
      return false;
    },
    [breakpointKey, cards.length]
  );

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (breakpointKey >= SMALL_DESKTOP) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.editorialProject__content__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 4) {
      goToCard((index.current += direction));
    } else {
      goToCard(index.current);
    }
  }, [breakpointKey, goToCard]);

  useEffect(() => {
    if (breakpointKey >= SMALL_DESKTOP) {
      cardsRef.current.classList.remove(`${styles.editorialProject__content__cards}--swipe`);
      cardsRef.current.style.transform = '';
    } else {
      goToCard(index.current);
    }
  }, [breakpointKey, goToCard]);

  const isWiderThanSmallDesktop = breakpointKey >= SMALL_DESKTOP;
  const cardList = [...cards];
  let lastCard;
  if (isWiderThanSmallDesktop) {
    lastCard = cardList.pop();
  }

  return (
    <section ref={stickyRef} className={styles.editorialProject}>
      <h2
        className={styles.editorialProject__title}
        dangerouslySetInnerHTML={{ __html: stripParagraphTag(caption.content) }}
      />
      <div className={styles.editorialProject__content}>
        <div className={styles.editorialProject__content__description}>
          <p>{description}</p>
          {cta.length && get(cta, '[0].uri') && get(cta, '[0].title') ? (
            <span>
              <Link to={{ pathname: `/${get(cta, '[0].uri')}` }} title={get(cta, '[0].title')}>
                Read More
              </Link>
            </span>
          ) : (
            ''
          )}
        </div>
        <Swipe
          ref={el => el && (cardsRef.current = el.swiper)}
          className={styles.editorialProject__content__cards}
          onSwipeStart={onSwipeStart}
          onSwipeMove={onSwipeMove}
          onSwipeEnd={onSwipeEnd}>
          {cardList.map((cardItem, key) => (
            <Card
              {...cardItem.card[0]}
              withBgColor={false}
              size="floating|standard"
              lazyload={false}
              key={key}
              className={styles.editorialProject__content__cards__link}
            />
          ))}
        </Swipe>
      </div>

      {lastCard ? (
        <Card
          {...lastCard.card[0]}
          withBgColor={false}
          size="floating|standard"
          lazyload={false}
          className={styles.editorialProject__big_card}
        />
      ) : null}
    </section>
  );
};

export default EditorialProject;
