import React, { createContext, useReducer, useContext, useCallback, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { get } from 'helpers/utilities';
import classNames from 'classnames/bind';
import { getAssociatedTaxonomy } from 'queries/twm_components';
import Icon from 'components/Icon';
import SentinelIntersect from 'components/SentinelIntersect';
import deviceInfo from 'helpers/deviceInfo';
import useBreakpoint from '../../hooks/useBreakpoint';
import useNavigation from '../../hooks/useNavigation';
import useGlobalBackground from '../../hooks/useGlobalBackground';
import useTaxonomies from '../../hooks/useTaxonomies';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const TEXTS = {
  features: 'Represent Us',
  cardgrid: 'Everyone'
};

const initialState = {
  isFilterOverlayOpen: false,
  isStickyFilterFixed: false,
  stickyIsInView: false,
  colorOption: '',
  intersectedComponent: ''
};

const StickyFilterContext = createContext({
  state: initialState,
  dispatch: () => {}
});

const SET_FILTER_OVERLAY_OPEN = 'SET_FILTER_OVERLAY_OPEN';
const SET_STICKY_FILTER_FIXED = 'SET_STICKY_FILTER_POSITION';
const SET_STICKY_FILTER_IS_IN_VIEW = 'SET_STICKY_FILTER_IS_IN_VIEW';
const SET_STICKY_FILTER_COLOR_OPTION = 'SET_STICKY_FILTER_COLOR_OPTION';
const SET_INTERSECTED_COMPONENT = 'SET_INTERSECTED_COMPONENT';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_FILTER_OVERLAY_OPEN:
      return { ...state, isFilterOverlayOpen: payload };
    case SET_STICKY_FILTER_FIXED:
      return { ...state, isStickyFilterFixed: payload };
    case SET_STICKY_FILTER_IS_IN_VIEW:
      return { ...state, stickyIsInView: payload };
    case SET_STICKY_FILTER_COLOR_OPTION:
      return { ...state, colorOption: payload };
    case SET_INTERSECTED_COMPONENT:
      return { ...state, intersectedComponent: payload };
    default:
      return state;
  }
};

export const StickyFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [JSON.stringify(state)]);
  return <StickyFilterContext.Provider value={value}>{children}</StickyFilterContext.Provider>;
};

export const useStickyFilter = () => {
  const { state, dispatch } = useContext(StickyFilterContext);

  const setFilterOverlayOpen = useCallback(
    payload => dispatch({ type: SET_FILTER_OVERLAY_OPEN, payload }),
    [dispatch]
  );
  const setStickyFilterFixed = useCallback(
    payload => dispatch({ type: SET_STICKY_FILTER_FIXED, payload }),
    [dispatch]
  );
  const setStickyFilterIsInView = useCallback(
    payload => dispatch({ type: SET_STICKY_FILTER_IS_IN_VIEW, payload }),
    [dispatch]
  );
  const setStickyFilterColorOption = useCallback(
    payload => dispatch({ type: SET_STICKY_FILTER_COLOR_OPTION, payload }),
    [dispatch]
  );
  const setStickyFilterIntersectedComponent = useCallback(
    payload => dispatch({ type: SET_INTERSECTED_COMPONENT, payload }),
    [dispatch]
  );

  return {
    ...state,
    setFilterOverlayOpen,
    setStickyFilterFixed,
    setStickyFilterIsInView,
    setStickyFilterColorOption,
    setStickyFilterIntersectedComponent
  };
};

const StickyFilterContainer = ({ title, transporterHeadRef }) => {
  const { state: navState, setNavVisible } = useNavigation();
  const data = useTaxonomies();
  const globalBackground = useGlobalBackground();
  const {
    isFilterOverlayOpen,
    stickyIsInView,
    colorOption,
    intersectedComponent,
    setStickyFilterIsInView,
    setFilterOverlayOpen
  } = useStickyFilter();
  const breakpoint = useBreakpoint();
  const firstItemIndex = useRef();
  const closeRef = useRef();
  const firstItemRef = useRef();
  const filterToggleRef = useRef();
  const filterRef = useRef();

  const toggleOverlay = useCallback(
    e => {
      if (e) e.stopPropagation();

      setNavVisible(false);
      setFilterOverlayOpen(!isFilterOverlayOpen);
    },
    [setNavVisible, isFilterOverlayOpen]
  );

  const changeBackground = useCallback(
    i => {
      setNavVisible(true);
      setStickyFilterIsInView(true);
      globalBackground.setCurrentIndex(i + 1);
      toggleOverlay();
    },
    [setStickyFilterIsInView, setNavVisible]
  );

  const focusHandler = useCallback(e => {
    if (e.target === closeRef.current && e.which === 9 && !e.shiftKey) {
      e.preventDefault();
      firstItemRef.current.focus();
    } else if (e.target === firstItemRef.current && e.which === 9 && e.shiftKey) {
      e.preventDefault();
      closeRef.current.focus();
    }
  }, []);

  const isBreakpointDesktop = breakpoint === 'smallDesktop' || breakpoint === 'largeDesktop';
  const filterShouldHaveOffset =
    transporterHeadRef &&
    transporterHeadRef.current &&
    ((isBreakpointDesktop && !deviceInfo.isSafari) || deviceInfo.isAndroid);

  const textStyles = cx('stickyFilter__text', {
    'stickyFilter__text--black': colorOption && colorOption === 'black',
    'stickyFilter__text--overlay-opened': isFilterOverlayOpen
  });
  const selectionStyles = cx('stickyFilter__filterSelection', {
    'stickyFilter__filterSelection--black': colorOption && colorOption === 'black'
  });
  const overlayStyles = cx('stickyFilter__overlay', {
    'stickyFilter__overlay--black': colorOption && colorOption === 'black'
  });
  const closeStyles = cx('stickyFilter__close', {
    'stickyFilter__close--black': colorOption && colorOption === 'black'
  });
  const selectButtonStyles = cx('stickyFilter__selectButton', {
    'stickyFilter__selectButton--out': isFilterOverlayOpen
  });
  const filterHeaderStyles = cx('stickyFilter__header', {
    'stickyFilter__header--with-offset': filterShouldHaveOffset,
    'stickyFilter__header--in-view': stickyIsInView && !navState.visible
  });
  const filterHeaderContainerStyles = cx('stickyFilter__header__container', {
    'stickyFilter__header__container--in-view': stickyIsInView && !navState.visible
  });

  const ariaAttribute = isFilterOverlayOpen ? { role: 'menuitem' } : {};
  const selectionText = TEXTS[intersectedComponent] || TEXTS.features;

  if (!data.length) {
    return null;
  }

  return (
    <SentinelIntersect
      config={{ threshold: [0, 1] }}
      onIntersection={(intersected, from) =>
        from === 'top' && !intersected && setStickyFilterIsInView(false)
      }
      topSentinelClass={styles.topSentinel}
      bottomSentinelClass={styles.bottomSentinel}>
      <div {...ariaAttribute} ref={filterRef} className={filterHeaderStyles}>
        <div className={filterHeaderContainerStyles}>
          <div className={`wrapper ${styles.stickyFilter__content}`}>
            <div
              className={textStyles}
              role="button"
              onClick={toggleOverlay}
              tabIndex="-1"
              aria-label="The Things We Make filter selection ">
              <span aria-hidden="true">
                The Things We Make
                <span className={styles.stickyFilter__preSelectText}>
                  &nbsp;
                  {isFilterOverlayOpen || intersectedComponent === 'cardgrid' || title !== ''
                    ? 'for'
                    : 'that'}
                </span>
              </span>
              <button
                ref={filterToggleRef}
                tabIndex={isFilterOverlayOpen ? '-1' : '0'}
                className={selectButtonStyles}
                type="button"
                onClick={toggleOverlay}
                aria-hidden={isFilterOverlayOpen ? 'true' : 'false'}
                aria-label={`${isFilterOverlayOpen ? 'Close' : 'Open'} filter selection overlay`}
                role="link">
                <span>&nbsp;{title !== '' ? title : selectionText}&nbsp;</span>
                <Icon name="chevronThickDown" color={colorOption || 'white'} size="small" />
              </button>
            </div>
            <CSSTransition
              in={isFilterOverlayOpen}
              timeout={1000}
              classNames={styles.stickyFilter__filterSelection}
              unmountOnExit={true}>
              <ul className={selectionStyles} aria-label="Things We Make for List">
                {data.map((filter, i) => {
                  const associatedTaxonomy = getAssociatedTaxonomy(get(filter, 'tagPageEntry[0]'));
                  if (!associatedTaxonomy) return null;
                  if (firstItemIndex.current === null) firstItemIndex.current = i;

                  const { tagTitle } = associatedTaxonomy;

                  const filterItemStyles = cx('stickyFilter__filterItem', {
                    'stickyFilter__filterItem--active':
                      (title === '' && tagTitle.toLowerCase() === 'everyone') || title === tagTitle
                  });

                  return (
                    <li key={i} className={filterItemStyles}>
                      <Link
                        onKeyDown={i === firstItemIndex.current ? focusHandler : null}
                        innerRef={i === firstItemIndex.current ? firstItemRef : null}
                        tabIndex={isFilterOverlayOpen ? '0' : '-1'}
                        to={{ pathname: `/${get(filter, 'tagPageEntry[0].uri')}` }}
                        title={tagTitle}
                        onClick={() => changeBackground(i)}
                        aria-label={`Go to ${tagTitle} page`}>
                        {tagTitle}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </CSSTransition>
            <CSSTransition
              in={isFilterOverlayOpen}
              timeout={500}
              classNames={styles.stickyFilter__close}
              onExiting={() => setNavVisible(false)}
              unmountOnExit={true}>
              <button
                onKeyDown={focusHandler}
                ref={closeRef}
                tabIndex={isFilterOverlayOpen ? '0' : '-1'}
                type="button"
                className={closeStyles}
                onClick={toggleOverlay}
                aria-label="Close filter selection overlay">
                <Icon name="close" color={colorOption || 'white'} />
              </button>
            </CSSTransition>
          </div>
        </div>
      </div>
      <CSSTransition
        in={isFilterOverlayOpen}
        timeout={500}
        classNames={styles.stickyFilter__overlay}>
        <div className={overlayStyles} />
      </CSSTransition>
    </SentinelIntersect>
  );
};

export default StickyFilterContainer;
