import canUseDOM from './canUseDOM';

/**
 * Check when an Element is in viewport.
 * @param {Element} element Element to check.
 * @return {Boolean} Wether the element is in viewport or not.
 */
export const isInViewport = element => {
  if (!canUseDOM) {
    return null;
  }

  const rect = element.getBoundingClientRect();
  const html = document.documentElement;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
};

/**
 * Check when the part of an Element is in Viewport.
 * @param {Element} element Element to check.
 * @return {Boolean} Wether the element is visible or not.
 */
export const isElementVisible = element => {
  if (!canUseDOM) {
    return null;
  }

  const rect = element.getBoundingClientRect();

  return rect.top < window.innerHeight || rect.top < 0;
};

export default isInViewport;
