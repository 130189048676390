import { get } from 'helpers/utilities';

export const pickFloatingOrStandardCard = data => {
  const floatingCard = get(data, 'floatingCardArtwork[0]');
  const standardCard = get(data, 'standardCardArtwork[0]');
  return floatingCard || standardCard;
};

export const pickFloatingOrFeatureCard = data => {
  const floatingCard = get(data, 'floatingCardArtwork[0]');
  const featureCard = get(data, 'featureCardArtwork[0]');
  return floatingCard || featureCard;
};
