import { flipProp } from 'helpers/fp';
import useBreakpoint from './useBreakpoint';

export const MOBILE = 0;
export const TABLET = 1;
export const SMALL_DESKTOP = 3;
export const LARGE_DESKTOP = 4;

const getBreakpointKey = flipProp({
  mobile: MOBILE,
  tablet: TABLET,
  smallDesktop: SMALL_DESKTOP,
  largeDesktop: LARGE_DESKTOP
});

const useBreakpointKey = () => {
  const breakpoint = useBreakpoint();

  return getBreakpointKey(breakpoint);
};

export default useBreakpointKey;
