import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';
import styles from './styles.scss';

const BrandSpotlight = ({ data, stickyRef }) => {
  const { brands, contentFields } = data;
  const { background, caption, description } = get(contentFields, '[0]');
  const top = brands.length > 4 ? `${styles.brandSpotlight__container}--top` : '';

  return (
    <section ref={stickyRef} className={styles.brandSpotlight}>
      <div className={styles.brandSpotlight__background}>
        <Image
          src={get(background, '[0].url')}
          alt={get(background, '[0].title')}
          lazyload={false}
        />
      </div>
      <div className={`${styles.brandSpotlight__container} ${top}`}>
        <div className={styles.brandSpotlight__heading}>
          <h2
            className={styles.brandSpotlight__title}
            dangerouslySetInnerHTML={{ __html: stripParagraphTag(get(caption, 'content')) }}
          />
          <p className={styles.brandSpotlight__description}>{description}</p>
        </div>
        <div className={styles.brandSpotlight__logos}>
          {brands.map((brand, index) => (
            <figure key={index} className={styles.brandSpotlight__logo}>
              <Image
                src={get(brand, 'logo[0].url')}
                alt={get(brand, 'brandName')}
                disableSrcSet={false}
                invert={true}
                width={100}
                brightness="-100"
                lazyload={false}
              />
              {get(brand, 'associatedPage') && get(brand, 'associatedPage[0].uri') ? (
                <Link
                  to={get(brand, 'associatedPage[0].uri')}
                  alt={get(brand, 'brandName')}
                  className={styles.brandSpotlight__logo__link}>
                  {get(brand, 'brandName')}
                </Link>
              ) : null}
            </figure>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandSpotlight;
