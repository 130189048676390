import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { checkMediaType } from 'helpers/media';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import Video from 'components/Video';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const TagHeader = ({ animateEntrance, pageBackground, title }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const tagHeaderRef = useRef();
  const tagHeaderStyles = cx('tagHeader', {
    'tagHeader--animate': animateEntrance && !isAnimated
  });

  useEffect(() => {
    const subscribeToScroll = e => {
      if (!document.querySelector('.fixedBackground')) {
        return;
      }
      if (e[0].isIntersecting) {
        document.querySelector('.fixedBackground').style.display = 'block';
      } else {
        document.querySelector('.fixedBackground').style.display = 'none';
      }
    };

    const option = { root: null, rootMargin: '0px', threshold: 0 };
    const scrollObserver = new IntersectionObserver(subscribeToScroll, option);

    scrollObserver.observe(tagHeaderRef.current);

    const animationEndListener = () => {
      // Remove the animate class to not initiate animation again.
      // Something is changing the main container display style which causes
      // this component to animate again.
      setTimeout(() => {
        setIsAnimated(true);
      }, 500);
    };

    tagHeaderRef.current.addEventListener('animationend', animationEndListener);

    return () => {
      scrollObserver.disconnect();
      tagHeaderRef.current.removeEventListener('animationend', animationEndListener);
    };
  }, []);

  const renderAsset = asset => {
    const pageBackgroundType = checkMediaType(get(asset, 'extension'));

    if (pageBackgroundType === 'image') {
      return <Image src={get(asset, 'url', '')} alt={get(asset, 'title')} />;
    }

    if (pageBackgroundType === 'video') {
      return (
        <Video
          autoPlay={true}
          loop={true}
          controls={false}
          muted={true}
          mp4={get(asset, 'extension') === 'mp4' ? get(asset, 'url') : false}
          webm={get(asset, 'extension') === 'webm' ? get(asset, 'url') : false}
        />
      );
    }

    return null;
  };

  return (
    <div ref={tagHeaderRef} className={`${tagHeaderStyles} articleHeader`} id="articleHeader">
      <div className="wrapper">
        <h2>{title}</h2>
      </div>
      <div className={styles.tagHeader__bg}>{renderAsset(pageBackground)}</div>
    </div>
  );
};

export default TagHeader;
