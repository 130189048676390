import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Swipe from 'react-easy-swipe';

// components
import Card from 'components/Cards';
import Image from 'components/Image';

// helpeers
import { get } from 'helpers/utilities';
import EventHandler from 'helpers/EventHandler';
import { EVENT, breakpoints } from 'config/constants';
import { stripParagraphTag } from 'helpers/text';

import { pickArticleHeaderContent } from 'queries/articleHeader';
import { pickFloatingOrStandardCard } from 'queries/cardArtwork';

// hooks
import useThrottle from '../../hooks/useThrottle';
import useDebounce from '../../hooks/useDebounce';

import styles from './style.scss';

const imageSizes = {
  mobile: 600,
  tablet: 300,
  smallDesktop: 550
};

const ScrollJackProject = props => {
  const { data } = props;

  const throttle = useThrottle();
  const debounce = useDebounce();
  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);
  const componentOffset = useRef(0);

  const cardsRef = useRef();
  const desktopCardsRef = useRef();
  const stickyContainerRef = useRef();
  const scrollJackDOM = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(-1);
  const intersecting = useRef(false);

  const { cards = [], featuredArticle, description } = data;
  const cardData = cards.filter(item => {
    if (typeof item.card[0].cardProject !== 'undefined' && item.card[0].cardProject.length) {
      return item;
    }
    if (typeof item.card[0].cardArticle !== 'undefined' && item.card[0].cardArticle.length) {
      return item;
    }
  });

  const currentIndex = useCallback(
    (idx = 0) => {
      let card = 0;

      if (idx < 0) {
        card = 0;
      } else if (idx > cardData.length - 1) {
        card = cardData.length - 1;
      } else if (window.innerWidth >= breakpoints.tablet && idx >= cardData.length - 1) {
        card = idx - 1;
      } else {
        card = idx;
      }

      return card;
    },
    [cardData.length]
  );

  /**
   * A function to calculate the top offset to apply to sticky element.
   * Each transported page is translated up the y axis to create the visual reveal.
   * We have to compensate the sticky offst b/c of this.
   *
   * @return number - the index of the parent times window height.
   */
  const calculateComponentOffset = useCallback(() => {
    const transporterPages = document.getElementsByClassName('page-transporter');
    let parentIndex = 0;
    let initialOffset = 0;

    const isDescendant = (parent, child) => {
      let node = child.parentNode;
      while (node !== null) {
        if (node === parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    };

    if (
      stickyContainerRef.current &&
      transporterPages.length > 0 &&
      window.innerWidth >= breakpoints.smallDesktop
    ) {
      initialOffset = parseInt(getComputedStyle(stickyContainerRef.current).top, 10);

      Array.from(transporterPages).forEach((page, idx) => {
        if (isDescendant(page, stickyContainerRef.current)) {
          parentIndex = idx;
        }
      });

      return parentIndex * window.innerHeight + initialOffset;
    }

    return 0;
  }, []);

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCurrentMargin = useCallback(() => {
    const currentMargin = window.getComputedStyle(cardsRef.current.children[1]).marginLeft;
    return currentMargin !== '0px' ? parseFloat(currentMargin) : 0;
  }, []);

  const getCardWidth = useCallback(
    () => cardsRef.current.children[0].clientWidth + getCurrentMargin()
  );

  const goToCard = useCallback(
    (idx = 0) => {
      const id = currentIndex(idx);
      const position = id * getCardWidth() * -1;
      index.current = id;
      originalX.current = position;
      cardsRef.current.style.transform = `translateX(${originalX.current}px)`;
    },
    [currentIndex]
  );

  const onSwipeStart = useCallback(() => {
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.scrollJackProject__cards}--swipe`);
  }, []);

  const onSwipeMove = useCallback(e => {
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return false;
    }

    if (!scrollDirection.current) {
      // threshold of 5 to determine
      // what scroll direction
      if (e.y > 5 || e.y < -5) {
        scrollDirection.current = 'vertical';
      } else {
        scrollDirection.current = 'horizontal';
      }
    }

    if (scrollDirection.current === 'horizontal') {
      currentX.current = e.x;
      cardsRef.current.style.transform = `translateX(${e.x + originalX.current}px)`;
      return true;
    }
    return false;
  }, []);

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.scrollJackProject__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 4) {
      goToCard((index.current += direction));
    } else {
      goToCard(index.current);
    }
  }, [goToCard]);

  useEffect(() => {
    componentOffset.current = calculateComponentOffset();

    const resizeHandler = (_, e) => {
      if (e.width >= breakpoints.smallDesktop) {
        cardsRef.current.classList.remove(`${styles.scrollJackProject__cards}--swipe`);
        cardsRef.current.style.transform = '';

        componentOffset.current = calculateComponentOffset();
      } else {
        goToCard(index.current);
      }
    };

    const scrollHandler = () => {
      if (scrollJackDOM.current && scrollJackDOM.current.offsetParent && intersecting.current) {
        const percentage = -(
          scrollJackDOM.current.getBoundingClientRect().top /
          (scrollJackDOM.current.clientHeight - window.innerHeight)
        );
        if (percentage >= 0 && percentage < 1 / 3 && activeIndex !== 0) {
          setPreviousIndex(-1);
          setActiveIndex(0);
        } else if (percentage >= 1 / 3 && percentage < 2 / 3 && activeIndex !== 1) {
          setPreviousIndex(0);
          setActiveIndex(1);
        } else if (percentage >= 2 / 3 && percentage <= 1 && activeIndex !== 2) {
          setPreviousIndex(1);
          setActiveIndex(2);
        }
      }
    };

    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, throttle(scrollHandler, 100));
    const resizeEvent = EventHandler.subscribe(EVENT.RESIZE, debounce(resizeHandler, 500));

    const config = {
      root: null,
      margin: '0',
      threshold: [0.25]
    };

    const intersectionObserver = new IntersectionObserver(entries => {
      Array.from(entries).map(entry => {
        if (entry.intersectionRatio >= 0.25) {
          intersecting.current = true;
        } else {
          intersecting.current = false;
        }
        return null;
      });
    }, config);

    intersectionObserver.observe(stickyContainerRef.current);

    return () => {
      EventHandler.unsubscribe(scrollEvent);
      EventHandler.unsubscribe(resizeEvent);
      intersectionObserver.disconnect();
    };
  }, [goToCard, activeIndex]);

  const article = {};
  const selected = get(featuredArticle, '[0].titleSelection[0].__typename');
  if (selected === 'TitleSelectionSelectEntryTitle') {
    article.title = pickArticleHeaderContent(
      get(featuredArticle, '[0].titleSelection[0].selected[0]')
    );
    article.uri = get(featuredArticle, '[0].titleSelection[0].selected[0].uri');
  } else if (selected === 'TitleSelectionCustomEntryTitle') {
    article.title = get(featuredArticle, '[0].titleSelection[0].customTitle.content');
  }

  const stickyOffsetStyle =
    componentOffset.current > 0
      ? {
          top: componentOffset.current
        }
      : {};

  return (
    <section className={styles.scrollJackProject} ref={scrollJackDOM}>
      <div
        className={`wrapper ${styles.scrollJackProject__wrapper}`}
        ref={stickyContainerRef}
        style={stickyOffsetStyle}>
        <div className={styles.scrollJackProject__container}>
          <div className={styles.scrollJackProject__content}>
            <div className={styles.scrollJackProject__inner}>
              <h2
                className={styles.scrollJackProject__title}
                dangerouslySetInnerHTML={{ __html: stripParagraphTag(article.title) }}
              />
              <p>{description}</p>
              {article.uri ? (
                <Link
                  to={`/${get(article, 'uri')}`}
                  className={styles.scrollJackProject__cta}
                  title="Read More">
                  Read More
                </Link>
              ) : null}
            </div>
            <Swipe
              ref={el => el && (cardsRef.current = el.swiper)}
              className={styles.scrollJackProject__cardsContainer}
              onSwipeStart={onSwipeStart}
              onSwipeMove={onSwipeMove}
              onSwipeEnd={onSwipeEnd}>
              {cardData.map((val, i) => (
                <Card
                  key={i}
                  {...get(val, 'card[0]')}
                  size="floating|standard"
                  className={styles.scrollJackProject__card}
                  lazyload={false}
                  zoom={true}
                  imageSizes={imageSizes}
                />
              ))}
            </Swipe>
          </div>
          <div className={styles.scrollJackProject__desktopCardsContainer} ref={desktopCardsRef}>
            {cardData.map((val, i) => {
              const cardType = Object.hasOwn(get(val, 'card[0]'), 'cardArticle')
                ? 'cardArticle'
                : 'cardProject';

              return (
                <div
                  key={i}
                  className={`${styles.scrollJackProject__desktopCard} ${
                    i === activeIndex ? styles['scrollJackProject__desktopCard--active'] : ''
                  } ${
                    i <= previousIndex && i !== cardData.length - 1
                      ? styles['scrollJackProject__desktopCard--previous']
                      : ''
                  }`}>
                  {get(val, `card[0].${cardType}[0]`) && (
                    <Link
                      to={`/${get(val, 'card[0].uri')}`}
                      className={styles.scrollJackProject__desktopCardTitle}
                      aria-label={`Go To ${get(val, 'card[0].title')}`}>
                      <span>
                        {cardType === 'cardProject' && (
                          <strong>{get(val, `card[0].${cardType}[0].caption`)}</strong>
                        )}
                        {cardType === 'cardArticle' && (
                          <strong>
                            {get(val, `card[0].${cardType}[0].caption.content`).replace(
                              /<\/?[^>]+(>|$)/g,
                              ''
                            )}
                          </strong>
                        )}{' '}
                        | {get(val, `card[0].${cardType}[0].description`)}
                      </span>
                    </Link>
                  )}
                  {get(val, `card[0].${cardType}[0]`) && (
                    <Link
                      to={`/${get(val, 'card[0].uri')}`}
                      className={styles.scrollJackProject__desktopCardImage}
                      aria-label={`Go To ${get(val, 'card[0].title')}`}
                      aria-hidden="true">
                      <Image
                        src={pickFloatingOrStandardCard(get(val, `card[0].${cardType}[0]`)).url}
                        alt={pickFloatingOrStandardCard(get(val, `card[0].${cardType}[0]`)).title}
                        lazyload={false}
                        sizes={imageSizes}
                      />
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollJackProject;
