import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';
import deviceInfo from 'helpers/deviceInfo';

import { get, lowerCase, upperFirst, keys } from 'helpers/utilities';
import { wrapWords } from 'helpers/text';

import Video from 'components/Video';
import HomepagePromotion from 'components/HomepagePromotion';
import Icon from 'components/Icon';

// hooks
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const getVideo = (data, screenBreakpoint) => {
  const breakpoint =
    screenBreakpoint === 'largeDesktop' || screenBreakpoint === 'smallDesktop'
      ? 'desktop'
      : screenBreakpoint;
  const videoBreakpointOrder = ['desktop', 'tablet', 'mobile'].sort(videoBreakpoint =>
    videoBreakpoint === breakpoint ? -1 : 1
  );
  const isUploadedVideo = get(data, 'videoType') === 'uploadedVideos';
  const videoProp = isUploadedVideo ? 'navigationVideoAsset' : 'navigationVideo';
  let video;

  videoBreakpointOrder.some(videoBreakpoint => {
    video = get(data, `${videoProp}${upperFirst(videoBreakpoint)}${isUploadedVideo ? '[0]' : ''}`);
    return video;
  });

  return video;
};

const NavItem = ({
  title,
  navigationHoverTitle,
  setVideoVisibilityFilter,
  uri,
  current,
  index
}) => {
  const isMobile = deviceInfo.isTouch && (deviceInfo.isIOS || deviceInfo.isAndroid);
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isShowHoverTitle, setIsShowHoverTitle] = useState(false);
  const [isInitialHover, setIsInitialHover] = useState(false);
  const spanRef = useRef(null);

  useEffect(() => {
    const setIsActiveFn = () => {
      if (!(current === index + 1) && !isHover) {
        setIsActive(false);
      }
    };
    if (spanRef.current) {
      spanRef.current.querySelector('span').addEventListener('transitionend', setIsActiveFn);
    }
    setTimeout(() => setIsShowHoverTitle(current === index + 1), 50);
    return () =>
      spanRef.current.querySelector('span').removeEventListener('transitionend', setIsActiveFn);
  }, [current, index]);

  useEffect(() => {
    if (isInitialHover && spanRef.current) return;
    const setIsActiveFn = () => {
      setIsActive(false);
    };
    if (current === index + 1) {
      setIsActive(true);
    } else {
      spanRef.current.querySelector('span').addEventListener('transitionend', setIsActiveFn);
    }
    return () =>
      spanRef.current.querySelector('span').removeEventListener('transitionend', setIsActiveFn);
  }, [current, index, isInitialHover]);

  return (
    <li className={isShowHoverTitle ? styles.active : ''}>
      <Link
        to={{ pathname: `/${uri}` }}
        title={`${title} - ${navigationHoverTitle}`}
        onMouseEnter={() => {
          if (isMobile) return;
          setIsInitialHover(true);
          setIsHover(true);
          setIsActive(true);
          setVideoVisibilityFilter(index + 1);
        }}
        onMouseLeave={() => {
          if (isMobile) return;
          setIsHover(false);
          setVideoVisibilityFilter(0);
        }}>
        <span
          className={styles.top}
          dangerouslySetInnerHTML={{ __html: wrapWords(title) }}
          ref={spanRef}
        />
        {isActive && (
          <span
            data-nosnippet
            className={styles.bottom}
            dangerouslySetInnerHTML={{ __html: wrapWords(navigationHoverTitle, 'span', 2) }}
          />
        )}
      </Link>
    </li>
  );
};

const HomeNavigation = ({
  data,
  recentNews,
  setVideoVisibilityFilter,
  current,
  watermark,
  initializePoll,
  hasFeatures
}) => {
  const breakpoint = useBreakpoint();
  const loadedVideos = useRef({});
  const offsetFrom = useRef(0);
  const offsetDist = useRef(0);
  const origTime = useRef(500);
  const initialTime = useRef(0);

  const generateLinks = () => {
    const navigationItemsData = data.filter(
      navigationItem => get(navigationItem, '__typename') === 'HomeNavigationNavigationItem'
    );
    const navigationItems = navigationItemsData.map((navigationItem, index) => {
      const { navigationHoverTitle, navigationLink } = navigationItem;
      const { title, uri } = get(navigationLink, '[0]');

      return (
        <NavItem
          key={index + 1}
          setVideoVisibilityFilter={setVideoVisibilityFilter}
          title={title}
          navigationHoverTitle={navigationHoverTitle}
          uri={uri}
          current={current}
          index={index}
        />
      );
    });

    return navigationItems;
  };

  const generateVideos = () => {
    const videos = data.map((videoData, index) => {
      const video = getVideo(videoData, breakpoint);
      const position = get(videoData, 'position', 'centerCenter');

      const videoContainerClassName = cx(
        styles.backgroundVideoContainer,
        styles[`backgroundVideoContainer--${position}`],
        {
          'backgroundVideoContainer--active': current === index
        }
      );

      if (video && loadedVideos.current[index] === undefined) {
        loadedVideos.current[index] = false;
      }

      return (
        <div className={videoContainerClassName} key={`video-${index}`}>
          {video ? (
            <Video
              autoPlay={true}
              muted={true}
              loop={true}
              key={index}
              src={video.url}
              togglePlay={true}
              watermark={watermark}
              onProgress={e => {
                if (keys(loadedVideos.current).every(key => loadedVideos.current[key])) return;
                if (e.loaded > 0 && !loadedVideos.current[index])
                  loadedVideos.current[index] = true;
                if (keys(loadedVideos.current).every(key => loadedVideos.current[key]))
                  initializePoll();
              }}
            />
          ) : null}
        </div>
      );
    });

    return videos;
  };

  function distancePerTime(currTime, origTime, offsetDist) {
    const per = currTime / origTime;
    return per * offsetDist;
  }

  function loopScroll(time) {
    initialTime.current = initialTime.current || time;
    const currTime = time - initialTime.current;
    const dist = distancePerTime(currTime, origTime.current, offsetDist.current);

    if (currTime <= origTime.current) {
      window.scrollTo(0, dist + offsetFrom.current);
      window.requestAnimationFrame(loopScroll);
    }
  }

  const scrollToFeatures = useCallback(() => {
    const featuresModuleContainer = document.querySelector('[class*="featuresModuleContainer"]');

    if (featuresModuleContainer) {
      offsetFrom.current = window.pageYOffset;
      offsetDist.current =
        featuresModuleContainer.offsetTop +
        featuresModuleContainer.offsetParent.offsetTop -
        offsetFrom.current;

      initialTime.current = 0;

      window.requestAnimationFrame(time => loopScroll(time));
    }
  }, [hasFeatures]);

  return (
    <section className={styles.homeNavigation}>
      <nav className={styles.navList}>
        <ul>{generateLinks()}</ul>
      </nav>
      {generateVideos()}
      {watermark && get(watermark, 'watermarkAsset', []).length > 0 && (
        <div
          className={styles.homeNavigation__watermark}
          style={{
            backgroundImage: `url(${get(watermark, 'watermarkAsset[0].url')})`,
            backgroundPosition: lowerCase(get(watermark, 'watermarkPosition', 'bottom right'))
          }}
        />
      )}
      <HomepagePromotion recentNews={recentNews} className={styles.homepagePromotion} />
      {hasFeatures && (
        <div className={styles.homeNavigation__cta}>
          <button
            aria-label="Scroll to Latest Updates"
            onClick={() => {
              scrollToFeatures();
            }}
            className={styles.homeNavigation__ctaButton}>
            <span className={styles.homeNavigation__ctaText}>Latest Updates</span>
            <Icon
              name="chevronThickDown"
              size="small"
              color="white"
              classes={styles.homeNavigation__ctaIcon}
            />
          </button>
        </div>
      )}
    </section>
  );
};

export default withRouter(HomeNavigation);
