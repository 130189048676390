import { struct } from 'superstruct';
import { wysiwygStruct, assetStruct, linkStruct, cardStruct } from 'helpers/structure';
import { createArray, compose, map } from 'helpers/fp';

const projectCards = compose(
  struct.tuple,
  map(() => cardStruct),
  createArray
);

// half up module
export const halfUpModuleStruct = struct.partial({
  projectData: struct.optional([
    struct.partial({
      projectDescription: 'string',
      projectEntry: linkStruct,
      projectTitleSelection: struct.tuple(['any'])
    }),
    'object?'
  ]),
  articleData: struct.optional(['object?'])
});

// liner project module
export const linearProjectStruct = struct.partial({
  caption: wysiwygStruct,
  cards: projectCards(3),
  description: 'string'
});

// editorial project module
export const editorialProjectStruct = linearProjectStruct;

// brand spotlight module
export const brandSpotlightStruct = struct.partial({
  contentFields: struct.tuple(['any']),
  brands: struct.list([
    struct.partial({
      brandName: 'string',
      logo: assetStruct
    })
  ])
});

export const sizzleVideoStruct = struct.partial({
  caption: wysiwygStruct,
  description: 'string',
  sizzleCards: struct.tuple([
    struct.partial({
      entryCard1: struct.tuple(['any']),
      entryCard2: struct.tuple(['any'])
    })
  ]),
  video: struct.partial({
    url: 'string',
    title: 'string'
  })
});

const awardCard = struct.partial({
  awardBackground: assetStruct,
  awardBrand: 'string',
  awardCategory: 'string',
  awardLink: linkStruct,
  awardName: 'string'
});
export const awardsStruct = struct.partial({
  caption: wysiwygStruct,
  awardCards: struct.tuple([awardCard, awardCard, awardCard])
});

export const singleBlowoutStruct = struct.partial({
  caseStudyTitle: wysiwygStruct,
  caseStudyLink: linkStruct,
  clientName: 'string',
  description: 'string',
  largeProjectAsset: assetStruct
});

export const scrollJackProjectStruct = struct.partial({
  description: 'string',
  featuredArticle: struct.tuple(['any']),
  cards: projectCards(3)
});

export const eventsStruct = struct.partial({
  eventsLinks: struct.list(['any'])
});

export const fullWidthVideoStruct = struct.partial({
  desktopTabletVideo: assetStruct
});
