import React, { memo } from 'react';
import SentinelIntersect from 'components/SentinelIntersect';
import { useStickyFilter } from '../StickyFilter';

const withStickyFilterSetter =
  ({ componentName = '', color = 'black' }) =>
  Component => {
    const MemoizedComponent = memo(Component);
    return props => {
      const stickyFilter = useStickyFilter();
      return (
        <SentinelIntersect
          onLoad={false}
          config={{ threshold: [0, 1] }}
          onIntersection={intersected => {
            stickyFilter.setStickyFilterIsInView(intersected);
            if (intersected) {
              stickyFilter.setStickyFilterColorOption(color);
              stickyFilter.setStickyFilterIntersectedComponent(componentName);
            }
          }}>
          <MemoizedComponent {...props} />
        </SentinelIntersect>
      );
    };
  };

export default withStickyFilterSetter;
