import gql from 'graphql-tag';
import cardQueries from './cardQueries';
import metaDetails from './metaDetails';
import { components } from './twm_components';

const { featuresModule } = components;

export default {
  main: `
        ... on PromotedTag {
            associatedType
            taxonomy {
                tagTitle
                pageBackground {
                    url
                    extension
                    title
                }
            }
            taxonomyConnection {
                edges {
                    relatedEntries (type: [CaseStudiesCaseStudy, ThoughtLeadership, CuratedWorkCuration]) {
                        entries {
                            id
                            uri
                            title
                            postDate
                            ${cardQueries.article}
                            ${cardQueries.project}
                            ${cardQueries.curatedWork}
                        }
                    }
                }
            }
            outputCategory {
                tagTitle
                pageBackground {
                    url
                    extension
                    title
                }
            }
            outputCategoryConnection {
                edges {
                    relatedEntries (type: [CaseStudiesCaseStudy, ThoughtLeadership, CuratedWorkCuration]) {
                        entries {
                            id
                            uri
                            title
                            postDate
                            ${cardQueries.article}
                            ${cardQueries.project}
                            ${cardQueries.curatedWork}
                        }
                    }
                }
            }
            ${featuresModule.name}
            ${metaDetails}
        }
    `,
  component: url => query =>
    gql`
        query {
            entry(uri: "${url}") {
                ... on PromotedTag {
                    ${query}
                }
            }
        }
    `
};
